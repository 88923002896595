<script>
export default {
  name: 'PageErrorTemplate',
};
</script>

<script setup>
defineProps({
  errorName: {
    type: String,
    default: '',
  },
  errorTitle: {
    type: String,
    default: '',
  },
  errorSubtitle: {
    type: String,
    default: '',
  },
  errorImageSrc: {
    type: String,
    default: '',
  },
  errorImageAlt: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <section :class="$style.container">
    <div :class="$style.info">
      <header :class="$style.header">
        <span :class="$style.errorName">
          {{ errorName }}
        </span>
        <h1 :class="$style.title">
          {{ errorTitle }}
        </h1>
        <h3 :class="$style.subTitle">
          {{ errorSubtitle }}
        </h3>
      </header>

      <div :class="$style.imageMobileContainer">
        <img
          :class="$style.image"
          :src="errorImageSrc"
          :alt="errorImageAlt"
        />
      </div>

      <div :class="$style.content">
        <slot name="infoContent">
          <i18n
            path="errors.errorGeneral.firstParagraph.content"
            tag="p"
          >
            <XLink
              to="https://clicksign.com"
              target="_blank"
              no-padding
            >
              {{ $t('errors.errorGeneral.firstParagraph.homePage') }}
            </XLink>
            <XLink
              to="https://status.clicksign.com"
              target="_blank"
              no-padding
            >
              {{ $t('errors.errorGeneral.firstParagraph.login') }}
            </XLink>
          </i18n>
        </slot>
        <slot name="helpContent">
          <i18n
            path="errors.errorGeneral.secondParagraph.content"
            tag="p"
          >
            <XLink
              :to="$t('links.help.host')"
              target="_blank"
              no-padding
            >
              {{ $t('errors.errorGeneral.secondParagraph.helpCenter') }}
            </XLink>
            <XLink
              to="mailto:ajuda@clicksign.com"
              no-padding
            >
              ajuda@clicksign.com
            </XLink>
          </i18n>
        </slot>
      </div>

      <footer :class="$style.footer">
        <XLink
          to="https://clicksign.com"
          target="_blank"
          no-padding
        >
          <XLogo color="black" />
        </XLink>
      </footer>
    </div>

    <div :class="$style.imageContainer">
      <img
        :class="$style.image"
        :src="errorImageSrc"
        :alt="errorImageAlt"
      />
    </div>
  </section>
</template>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: center;
  padding: var(--space-large-x) var(--space-regular);

  @media (max-width: 768px) {
    padding: var(--space-regular);
  }
}

.info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 640px;

  .header {
    .errorName {
      text-transform: uppercase;
      color: var(--color-neutral-700);
    }

    .subTitle {
      font-weight: var(--font-weight-400);
      color: var(--color-neutral-700);
    }

    @media (max-width: 768px) {
      .title {
        font-size: var(--font-size-medium);
      }

      .subTitle {
        font-size: var(--font-size-regular);
      }
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

.imageMobileContainer {
  display: none;
  align-items: center;
  width: 100%;
  margin-bottom: var(--space-medium);

  @media (max-width: 768px) {
    display: flex;
  }
}

.imageContainer {
  flex-grow: 1;
  max-width: 570px;

  @media (max-width: 768px) {
    display: none;
  }
}

.image {
  width: 100%;
  height: auto;
}

.footer {
  margin-top: var(--space-regular);
}
</style>
